#book-app {
  --thickness: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 12rem;
  perspective: 1500px;

  @media (max-width: 768px) {
    --thickness: 10px;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  &[data-current-media="book"] {
      .book-wrapper {
          display: inline-block;
      }
  }

  .book-wrapper {
    &:hover .book {
      transform: rotateX(0deg) rotateY(-50deg) translateY(-0.25rem);

      &__front {
        filter: saturate(1);
      }
    }

    &:hover .book-shadow {
      transform: rotateX(90deg) rotateZ(40deg);
    }
  }

  .book-wrapper {
    display: none;
  }

  .book {
    position: relative;
    z-index: 1;
    width: 16rem;
    transform-style: preserve-3d;
    transition: transform 1s ease;
    transform: rotateX(0deg) rotateY(-30deg);

    @media (max-width: 768px) {
      width: 8rem;
    }

    @media (max-width: 500px) {
      width: 6rem;
    }

    &__front {
      border-radius: 3px;
      overflow: hidden;
      filter: saturate(0);
      transition: filter 1s ease;
      transform: translateZ(calc(var(--thickness)));

      img {
        transform:scale(105%);
      }
    }

    &__back {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background: #0b0b20;
        border-radius: 5px;
        overflow: hidden;
        transform: translateZ(calc(-1 * var(--thickness)));
    }

    &__paper {
        height: 99%;
        width: calc(var(--thickness) * 2);
        background: white;
        position: absolute;
        top: 1%;
        right: 0;
        background:
            linear-gradient(90deg, rgba(0, 0, 0, 0.3), rgba(255, 255, 257, 0)),
            repeating-linear-gradient(90deg, white, var(--paperwhite) 1px, white 3px, #9a9a9a 1px);
        transform: rotateY(90deg) translateX(calc(var(--thickness) / 7)) translateZ(calc(var(--thickness) / 1.2));
    }
  }

  .book-shadow {
    position: absolute;
    width: 300px;
    height: calc(25px + var(--thickness));
    background: radial-gradient(70% 85%, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
    transform: rotateX(90deg) rotateZ(30deg);
    transition: transform 1s ease;
    filter: blur(32px);

    @media (max-width: 768px) {
      width: 100px;
    }
  }
}

#open-book-shadow {
  width: 45%;
  position: absolute;
  bottom: -7vw;
  left: 50%;
  transform: translateX(-50%);
  -o-object-fit: contain;
     object-fit: contain;
  z-index: 1337;
}

#open-book {
  width: 45%;
  position: absolute;
  bottom: -5vw;
  left: 50%;
  transform: translateX(-50%);
  -o-object-fit: contain;
     object-fit: contain;
  z-index: 1337;
}
